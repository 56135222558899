@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.radio {
	display: flex;

	label {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:before {
			content: '';
			box-sizing: border-box;
			display: block;
			width: 15/16+rem;
			min-width: 15/16+rem;
			height: 15/16+rem;
			border-radius: 50%;
			border: 2/16+rem solid $silver-chalice;
			margin-right: .5rem;
			transition: border .25s;
		}

		&:hover {

			&:before {
				border-color: $conflower-blue;
			}
		}
	}

	input {
		display: none;

		&:checked + label {

			&:not(:hover) {

				&:before {
					border-color: #000;
				}
			}

			&:after {
				content: '';
				box-sizing: border-box;
				position: absolute;
				top: 5/16+rem;
				left: 5/16+rem;
				width: 5/16+rem;
				height: 5/16+rem;
				border-radius: 50%;
				background: #000;
				transition: background .25s;
			}

			&:hover {

				&:after {
					background: $conflower-blue;
				}
			}
		}
	}
}