@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.events {
	width: 100%;
}

.header {
	display: flex;
	align-items: center;
	margin-bottom: 10/16+rem;
}

.switch {
	margin-right: 10/16+rem;
}

.title {
	margin-right: auto;
	@include f1-700();
	font-size: 20/16+rem;
	line-height: 24/20+em;
	color: $mine-shaft-1;
}