@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.selectAll {
	@extend %button;
	cursor: pointer;
	@include f1-400();
	font-size: 17/16+rem;
	line-height: 21/17+em;
	color: $conflower-blue;
	text-transform: uppercase;
}

.list {
	margin: 0 -1rem;

	&Select {

		&:global {

			&.select {
				width: 120/16+rem;
			}
		}
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
}