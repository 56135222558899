@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	align-items: stretch;
	height: 100%;
}

.list {
	margin-right: 1rem;
	max-width: 250/16+rem;
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 10/16+rem;
	height: 29/16+rem;
	border-left: 7/16+rem solid $alto-2;

	&:global {

		&._blue {
			border-color: $blue-ribbon;
		}

		&._green {
			border-color: $jungle-green;
		}
	}

	&:not(:last-child) {
		margin-bottom: .5rem;
	}

	&Title,
	&Value {
		color: $mine-shaft-1;
	}

	&Title {
		padding-right: .5rem;
		color: $gray;
	}
}

.wrapper {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	min-width: 0;
	height: 100%;
}

.types {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.type {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1rem;
	height: 30/16+rem;
	border-radius: 15/16+rem;
	border: 1/16+rem solid transparent;
	cursor: pointer;
	@include f1-400();
	font-size: 15/16+rem;
	line-height: 18/15+em;
	color: $melrose;
	text-align: center;

	&:global {

		&._active {
			border-color: #13193e;
			color: $mine-shaft-1;
		}
	}

	&:focus {
		outline: none;
	}
}