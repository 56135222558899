@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	margin-bottom: -1rem;

	&Row {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&Select {

		&:global {

			&.select {
				margin-left: 1rem;
				width: 10rem;
			}
		}
	}
}

.close {
	margin-right: 1rem;
}

.title {
	margin-right: auto;
	white-space: nowrap;
}

.pending {
	justify-content: space-between;
	margin: 0 -1rem;
	padding: 1rem;
	background: linear-gradient(to right, #2A5671, #13193E);

	&Title {
		@include f1-900();
		font-size: 1rem;
		line-height: 1.15;
		color: #fff;
		letter-spacing: -.025em;
		white-space: nowrap;
	}

	&Approve {

		&:global {

			&.btn-round {
				padding: 0 .5rem;
			}
		}
	}
}

.list {
	margin: 0 -1rem;

	&Header {
		display: flex;
		align-items: center;
		padding-left: 36/16+rem;
		height: 54/16+rem;
	}

	&Th {
		display: flex;
		align-items: center;
		@extend %text;

		&:global {

			&._name {
				min-width: 114/16+rem;
				max-width: 114/16+rem;
			}
		}

		svg {
			margin-left: .25rem;
			width: 20/16+rem;
			height: auto;
			cursor: pointer;
		}

		path {

			&:first-child {
				fill: #000;
			}
		}
	}
}

.item {
	display: flex;
	align-items: center;
	padding: 0 .5rem;
	width: 100%;
	max-width: 100%;
	height: 54/16+rem;
	border-top: 1/16+rem solid $alto-2;
}

.checkbox {

	&:global {

		&.checkbox {

			.checkbox {

				&__label {

					svg {
						margin: 0 .5rem 0 0;
					}
				}
			}
		}
	}
}

.wrapper {
	margin-right: auto;
	flex: 0 1 auto;
	min-width: 0;
}

.name {
	overflow: hidden;
	@extend %text;
	color: #000;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.param {
	margin-top: .25rem;
	font-size: .75rem;
	line-height: 15/12+em;
	color: $dove-gray
}

.created {
	margin-left: 1rem;
	min-width: 80/16+rem;
	max-width: 80/16+rem;
	@extend %text;
	color: $dove-gray;
}

.more {
	flex: 0 0 auto;
	@extend %button;
	margin-left: 1rem;
	cursor: pointer;
	@extend %text;
	color: $conflower-blue;
	text-transform: uppercase;
}

.controls {
	display: flex;
	align-items: center;
	height: 50/16+rem;

	&Btn {

		&:global {

			&.btn-round {
				padding: 0 1rem;
				min-width: 0;
			}
		}
	}
}

.remove {
	margin-right: .5rem;
	width: 16/16+rem;
	min-width: 16/16+rem;
	height: 16/16+rem;
	border-radius: 50%;
	background: $alto;

	&:before,
	&:after {
		width: 10/16+rem;
		height: 1/16+rem;
		background: #fff;
		border-radius: 1/16+rem;
	}
}

.selected {
	flex: 1 1 auto;
	min-width: 0;
	margin-right: 5/16+rem;
	overflow: hidden;
	@extend %text;
	color: $dodger-blue;
	white-space: nowrap;
	text-overflow: ellipsis;
}