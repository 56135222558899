@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin-bottom: .5rem;
}

.textField {
	margin-left: auto;
	flex: 1 1 auto;
	max-width: 200/16+rem;
}

.viewOnly {
	display: flex;
	align-items: center;

	&:global {

		&._disabled {

			:local {

				.label {
					opacity: .5;
				}
			}
		}
	}
}

.label {
	margin-right: .5rem;
	@include f1-400();
	font-size: 15/16+rem;
	line-height: 18/15+em;
	color: $silver-chalice;
}

.assigned {
	margin-left: 1.5rem;
	margin-bottom: .25rem;
	font-size: 11/16+rem;
	line-height: 13/11+em;
	color: $tundora;
}

.reassign {
	@extend %button;
	margin-left: .75rem;
	font-size: 11/16+rem;
	line-height: 13/11+em;
	color: $conflower-blue;
	text-transform: uppercase;

	&:disabled {
		opacity: .5;
	}
}

.warning {
	margin-top: .25rem;
	width: 100%;
	@include f1-700();
	font-size: 15/16+rem;
	line-height: 18/15+em;
	color: $bitter-sweet;
}