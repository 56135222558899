@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.sidebar {
	display: flex;
	flex-direction: column;
  width: 100%;
	background: #fff;
	transition: transform linear 0.3s;

	&:global {

		&._right-sidebar {
			width: 320/16+rem;
			box-shadow: 0 0 .25rem 0 rgba($gray,.5);
		}
	}
}

.container {
	flex: 1 1 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	transition: opacity .25s .125s, transform .25s .125s;

	&:global {

		&._hidden {
			pointer-events: none;
			opacity: 0;
			transform: scale(.9);
			transition-delay: 0s;
		}
	}
}

.header {
	flex: 0 0 auto;
	padding: 1rem;
	border-bottom: 1/16+rem solid $wild-sand;

	& + .main {
		padding-top: 0;
	}
}

.main {
	flex: 1 1 auto;
	display: flex;
	align-items: stretch;
	padding: 1rem;
	width: 100%;
	overflow: auto;
}

.content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
}

.footer {
	flex: 0 0 auto;
}
