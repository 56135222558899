@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 0 -1rem;
	padding: 1rem .5rem;
}

.header {
	display: flex;
	align-items: center;
	margin: 0 -1rem;
	padding: 0 .5rem;
}

.back {
	margin-left: -10/16+rem;
	transform: translateY(.125rem);
}

.title {
	margin-right: .5rem;
	@include f1-900();
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: .5rem;
	}

	&:global {

		&.row {
			margin-left: -.25rem;
			margin-right: -.25rem;
		}
	}

	[class^="col"] {
		padding: 0 .25rem;
	}
}

.table {
	margin-bottom: auto;
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	padding: 0;
	border: none;

	th {
		@include f1-400();
		font-size: 15/16+rem;
		line-height: 18/15+em;
		color: $gray;
		text-align: left;

		&:nth-child(2) {
			text-align: center;
		}

		&:last-child {
			text-align: right;
		}
	}

	td {
		vertical-align: middle;
		padding: 1rem 0;
		text-align: left;

		&:nth-child(2) {
			text-align: center;
		}

		&:last-child {
			text-align: right;
		}

		&:not(:last-child) {
			padding-right: 5/16+rem;
		}
	}

	&Btn {
		@extend %button;
		cursor: pointer;
		@include f1-400();
		font-size: 15/16+rem;
		line-height: 18/15+em;
		color: $conflower-blue;
	}

	&CircleBtn {
		width: 1.5rem;
		height: 1.5rem;
	}

	&TrAdd {

		td {
			vertical-align: top;
		}
	}
}

.label {
	margin-bottom: .5rem;
	@extend %text;
	color: $silver-chalice;
}

.textarea {
	padding: .5rem;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	border-radius: 9/16+rem;
	border-color: $alto-1;

	&:global {

		&._note {
			min-height: 10.5rem;
			max-height: 10.5rem;
			height: 10.5rem;
		}

		&._form {
			min-height: 3rem;
			max-height: 3rem;
			height: 3rem;
		}
	}

	&:focus {
		outline: none;
	}
}

.finances {

	&Row {
		display: flex;
		justify-content: space-between;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		&:global {

			&._total {

				&:not(:last-child) {
					margin-bottom: 1.5rem;
				}

				:local {

					.finances {

						&Col {
							@include f1-700();
						}
					}
				}
			}
		}
	}

	&Col {
		font-size: 20/16+rem;
		line-height: 30/25+em;
		color: #000;
	}
}

.form {
	margin-bottom: 2rem;

	&Item {

		&:not(:last-child) {
			margin-bottom: .5rem;
		}
	}
}

.datespicker {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	&Timezone {
		margin-bottom: 5/16+rem;
		width: 100%;
	}
}