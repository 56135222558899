@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: .75rem;
	padding: 0 1rem;
}

.period {
	display: flex;
	align-items: center;
	font-size: 1rem;
	line-height: 19/16+em;

	svg {
		width: 20/16+rem;
	}

	path {

		&:first-child {
			fill: #000;
		}
	}
}

.list {
	flex: 1 1 auto;
	min-height: 0;
	overflow: auto;
}

.item {
	display: flex;
	align-items: center;
	padding: 0 0 0 1rem;
	min-height: 58/16+rem;
	border-top: 1/16+rem solid $alto-2;

	&:last-child {
		border-bottom: 1/16+rem solid $alto-2;
	}
}

.status {
	margin-right: .5rem;
	width: 9/16+rem;
	min-width: 9/16+rem;
	height: 9/16+rem;
	border-radius: 50%;
	background: $aqua-forest-1;
}

.info {
	flex: 1 1 auto;
	min-width: 0;
	margin-right: .5rem;
}

.name {
	overflow: hidden;
	@include f1-400();
	font-size: 1rem;
	line-height: 19/16+em;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.id {
	margin-top: .25rem;
	font-size: .75rem;
	line-height: 15/12+em;
	color: $dove-gray;
}

.sum {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	@include f1-400();
	font-size: 1rem;
	line-height: 19/16+em;
}

.change {
	margin-top: .25rem;
	font-size: .75rem;
	line-height: 15/12+em;
	color: #00A617;
}

.btnMore {
	width: 28/16+rem;
	height: 28/16+rem;
}