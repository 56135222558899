@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.btnGroup {
	margin-bottom: 1rem;
	align-self: center;
}

.list {
	margin: 0 -1rem;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
}