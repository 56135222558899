@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.content {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
}

.header {

	&Row {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&Select {

		&:global {

			&.select {
				margin-left: 1rem;
				width: 10rem;
			}
		}
	}
}

.close {
	margin-right: 1rem;
}

.title {
	margin-right: auto;
	white-space: nowrap;
}

.chart {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 2rem;
	height: 10rem;

	&Block {
		margin: 0 -1rem 2.5rem;
		padding: 1rem 1rem 0;
		background: linear-gradient(-45deg, #2A5671, #13193E);
	}

	&Warning {
		margin-bottom: 10/16+rem;
		font-size: 14/16+rem;
		line-height: 17/14+em;
		color: #FFBABA;

		b {
			@include f1-700();
		}
	}
}

.funds {
	position: relative;
	margin-bottom: -1.5rem;
	padding: 10/16+rem 1rem;
	background: #fff;
	box-shadow: 0 2/16+rem 4/16+rem 0 rgba(#000,.5);

	&Add {
		position: absolute;
		top: -10/16+rem;
		right: 20/16+rem;
		width: 40/16+rem;
		height: 40/16+rem;
		transform: rotate(45deg);
		border-radius: 50%;
		background: $conflower-blue;
		box-shadow: 0 2/16+rem 4/16+rem 0 rgba(#000,.5);

		&:before,
		&:after {
			width: 1rem;
			height: 2/16+rem;
			border-radius: 0;
			background: #fff;
		}
	}

	&Title {
		@include f1-400();
		margin-bottom: .25rem;
		font-size: 21/16+rem;
		line-height: 25/21+em;
	}
}

.credit {

	&Available {
		display: flex;
		align-items: baseline;
		margin-right: .5rem;
		font-size: 13/16+rem;
		line-height: 16/13+em;
		color: $dove-gray;
		white-space: nowrap;
	}

	&Preapprove {
		@extend %button;
		margin-left: auto;
		cursor: pointer;
		@include f1-400();
		font-size: .75rem;
		line-height: 15/12+em;
		color: $conflower-blue;
		text-transform: uppercase;
		white-space: nowrap;
	}
}

.list {
	flex: 1 1 auto;
	min-height: 0;
	margin: 0 -1rem;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	padding: 1rem .5rem 0;
}