@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	:global {

		.table {

			&__top {
				display: none;
			}

			&__cell {

				&-content {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.header {
	display: flex;
	align-items: flex-end;
	margin-bottom: .5rem;

	&Search {
		margin-left: auto;
		margin-right: 1rem;
		width: 10.5rem;
	}

	&Select {

		&:global {

			&.select {
				width: 192/16+rem;
			}
		}
	}
}

.table {
	max-width: 100%;

	:global {

		td {

			&._customer {

				@include td-width(135, 300);
			}

			&._dateAdded {

				@include td-width(100, 135);
			}

			&._assignees {

				@include td-width(100, 100);
			}

			&._activeCampaigns {

				@include td-width(140, 140);
			}

			&._userBudget {

				@include td-width(100, 150);
			}

			&._userSpent {

				@include td-width(100, 150);
			}
		}
	}
}