@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 1rem;
	background: #fff;
	transition: opacity .25s .125s, transform .25s .125s;

	&:global {

		&._hidden {
			z-index: -1;
			pointer-events: none;
			opacity: 0;
			transform: scale(.9);
			transition-delay: 0s;
		}
	}
}

.title {
	margin-bottom: 1rem;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}