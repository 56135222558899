@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	padding: 0 2rem 1rem;
	transition: padding linear 0.3s;

	&:global {

		&._right-sidebar-opened {
			padding-right: 86/16+rem;
		}
	}
}