@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	flex-grow: 1;
	padding-top: 1.5rem;
	padding-bottom: 70/16+rem;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20/16+rem;
}

.uploadBtn {
	display: flex;
	align-items: center;

	i {
		margin-right: .5rem;
		width: 13/16+rem;
		line-height: 0;
	}

	svg {
		width: 100%;
		height: auto;
	}

	path {
		fill: $conflower-blue;
		transition: fill .25s;
	}

	&:hover {

		path {
			fill: $indigo;
		}
	}
}