body {

	.datepicker {

		&._sm {

			.date-range-picker {
				width: 100%;

				&__wrapper {
					width: 100%;

					&-inner {
						z-index: 2;
					}
				}
			}

			.date-picker {
				padding-left: .25rem;
				padding-right: .25rem;
				width: 100%;

				&:after {
					z-index: 3;
					left: 10/16+rem;
				}

				&__icon {
					margin-right: 0;
				}

				&__item {

					&:first-child {
						margin-right: 0;
					}

					&:last-child {
						margin-left: 5/16+rem;
					}
				}
			}
		}
	}
}