@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.switch {
	align-self: center;
	margin-bottom: 1.5rem;
}

.item {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: stretch;
	min-height: 61/16+rem;
}