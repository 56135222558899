@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.campaign {
	margin: 0 -1rem;
}

.header {
	display: flex;
	align-items: center;
	height: 4rem;
	padding: 0 .5rem;
	border-bottom: 1/16+rem solid $dusty-gray;

	&Wrapper {
		flex: 1 1 auto;
		min-width: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-right: .5rem;
	}
}

.back {
	margin-left: -6/16+rem;
	margin-right: .5rem;
}

.title {
	width: 100%;
	overflow: hidden;
	@extend %text;
	color: #000;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.id {
	margin-top: .25rem;
	font-size: .75rem;
	line-height: 15/12+em;
	color: $dove-gray
}

.created {
	flex: 0 0 auto;
	margin-left: auto;
	font-size: .75rem;
	line-height: 15/12+em;
	color: $dove-gray
}

.collapses {

}

.collapse {

	&:last-child {
		border-bottom: 1/16+rem solid $dusty-gray;
	}

	&Content {
		padding: 0 .5rem 1rem;
	}

	&Btn {
		height: 54/16+rem;
		border-bottom: none;
		@include f1-700();
		font-size: 1rem;
		line-height: 1.15;
		color: #000;

		span {
			@extend %text;
			color: $dusty-gray;
		}
	}

	&:not(:first-child) {

		.collapseBtn {
			border-top: 1/16+rem solid $dusty-gray;
		}
	}
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: .5rem;
	}

	&:global {

		&.row {
			margin-left: -.25rem;
			margin-right: -.25rem;
		}
	}

	[class^="col"] {
		padding: 0 .25rem;
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	padding: 1rem .5rem;

	& > * {

		&:not(:last-child) {
			margin-right: 1rem;
		}
	}
}

.datespicker {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	&Timezone {
		margin-bottom: 5/16+rem;
		width: 100%;
	}
}