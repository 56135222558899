// COLORS
$mine-shaft-1: #1f1f1f;

$fog: #dce1ff;

// OLD
$titan-white: #EBEEFF;
$alto-2: #d1d1d1;
$mercury: #e8e8e8;
$dove-gray-1: #696969;
$tundora: #4c4c4c;

$manhattan: #f7ce85;

$titan-white: #EBEEFF;
$dodger-blue-1: #3399FF;
$blue-ribbon: #2C47F7;
$jacksons-purple: #1c2b89;

$jungle-green: #28BC97;
$aqua-forest-1: #4E9E7D;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;