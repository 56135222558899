@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.list {

	&:global {

		&._tile {
			display: flex;
			flex-wrap: wrap;
			padding: 0 14/16+rem;

			:local .item {
				flex: 0 1 33.333%;
				min-width: 33.333%;
				padding: 0 8/16+rem 1rem;
				border: none;
			}

			&._column {
				flex-direction: column;
				align-items: stretch;
				margin: 1rem -.5rem 0;
				padding: 0;

				:local {

					.item {
						flex: 0 0 auto;
						min-width: 0;
					}
				}
			}
		}
	}
}

.item {
	border-bottom: 1/16+rem solid $dusty-gray;

	&:first-child {
		border-top: 1/16+rem solid $dusty-gray;
	}
}