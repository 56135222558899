@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	padding: 1rem 0 0;
}

.header {
	display: flex;
	align-items: flex-end;
	margin-bottom: .5rem;

	&Select {

		&:global {

			&.select {
				width: 10.5rem;
			}
		}
	}
}

.name {
	margin-right: auto;
}

.snackbar {

	&Btn {
		color: $orange;
	}
}

.btn {

	&:global {

		&.btn-round {
			padding: 0 .75rem !important;
			min-width: 0 !important;
			border: 1px solid $alto-1 !important;
			color: #000 !important;
		}
	}
}