body {

	.text-field-wrapper {

		&._no-label {

			.text-field__base {
				margin-top: 0;
			}
		}
	}
}