@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.collapse {

}

.btn {
	@extend %button;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10/16+rem;
	width: 100%;
	border-bottom: 1/16+rem solid $dusty-gray;
	cursor: pointer;
	@include f1-400();
	font-size: 20/16+rem;
	line-height: 24/20+em;
	color: $mine-shaft-1;

	svg {
		width: 2rem;
		height: auto;
	}

	path {

		&:first-child {
			fill: #000;
		}
	}
}

.collapse {

	&:global {

		&._opened {

			:local {

				.icon {
					transform: rotate(180deg);
				}
			}
		}
	}
}

.content {
	display: none;
}

.collapse {

	&:global {

		&._opened {

			:local {

				.content {
					display: block;
				}
			}
		}
	}
}