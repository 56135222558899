@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.dialog {

	&:global {

		&.dialog {
			display: flex;
			align-items: stretch;
			height: calc(100vh - 40rem / 16);
			width: 60%;
			max-width: none;
		}
	}

	:global {

		.dialog__content {
			width: 100%;
		}
	}
}

.container {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 0;
}

.header {
	margin-bottom: .5rem;
	display: flex;
	align-items: center;
}

.icon {
	margin-right: 14/16+rem;
}

.title {
	margin-right: auto;
	@include f1-700();
}

.size {
	margin-left: .5rem;
	@include f1-500();
	font-size: 12/16+rem;
	line-height: 15/12+em;
	color: $silver-chalice;
}

.close {
	margin-left: 20/16+rem;
}

.tabs {
	margin-bottom: 20/16+rem;
}
.image {
	flex: 1 1 auto;
	max-height: calc(100% - 81rem/16);
	width: 100%;
	line-height: 0;
	text-align: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}