@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	position: relative;

	&:global {

		&._opened {

			:local {

				.btn {
					position: relative;
					z-index: 2;

					&:global:not(._tile) {

						svg {
							width: 1.5rem;
						}

						path {

							&:nth-child(2) {
								display: none;
							}
						}
					}
				}

				.list {
					position: absolute;
					z-index: 1;
					top: -5/16+rem;
					right: -10/16+rem;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 210/16+rem;
					padding: 12/16+rem 20/16+rem;
					border-radius: 9/16+rem;
					border: 1/16+rem solid $alto-1;
					background: #fff;
					box-shadow: 0 0 12/16+rem 0 rgba($silver-chalice,.5);
				}
			}

			&._tile {
				left: 0;
				right: 0;
				bottom: 0;
				background: #fff;
				border-radius: 0 0 9/16+rem 9/16+rem;
				overflow: hidden;

				:local {

					.btn {
						position: absolute;
						top: .5rem;
						right: .5rem;
						width: 1rem;
						height: 1rem;

						svg {
							width: 10/16+rem;
							height: auto;

							path {
								fill: $gray;
							}
						}
					}

					.list {
						position: static;
						display: flex;
						flex-direction: row;
						align-items: stretch;
						padding: 0;
						width: 100%;
						height: 100%;
						border: none;
					}

					.title {
						display: none;
					}

					.itemBtn {
						flex: 1 1 33.333%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						&:nth-child(n) {
							margin: 0;
						}

						&:not(:last-child) {
							border-right: 1/16+rem solid $nobel;
						}

						&:hover {

							path {
								fill: $conflower-blue;
							}
						}
					}

					.icon {
						margin: 0 0 .5rem;
						width: 18/16+rem;

						svg {
							width: 100%;
							height: auto;

							path {
								fill: $gray;
							}
						}
					}
				}
			}
		}
	}

	.btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		@extend %button;
		cursor: pointer;

		svg {
			width: 1rem;
			height: auto;
			pointer-events: none;
		}

		path {
			fill: $gray;
		}
	}
}

.list {
	display: none;
}

.title {
	margin-bottom: 1rem;
	@include f1-700();
}

.item {

	&Btn {
		display: flex;
		align-items: center;
		@extend %button;
		cursor: pointer;
		transition: color .25s;
		@include f1-400();
		font-size: 14/16+rem;
		line-height: 2;
		color: $gray;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 5/16+rem;
		}

		path {
			transition: fill .25s;
		}

		&:hover {
			color: $conflower-blue;

			path {
				fill: $conflower-blue;
			}
		}
	}
}

.icon {
	margin-right: 12/16+rem;

	svg {
		display: block;
		width: 14/16+rem;
		height: auto;
	}
}

.modalTitle {
	font-size: 22/16+rem;
	line-height: 26/22+em;
}