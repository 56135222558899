.block {
	padding: 1rem;
	border-radius: 9/16+rem;
	border: 1/16+rem solid $alto-1;
	background: #fff;
	box-shadow: 0 0 .75rem 0 rgba($mercury,.5);

	&Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.5rem;
	}

	&Row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&Title {
		display: flex;
		align-items: center;

		path {
			fill: $gray;
		}
	}

	&Tooltip {
		margin-left: .5rem;
		line-height: 0;

		:global {

			.tooltip {

				&__label {
					line-height: 17/14+em;
				}
			}
		}
	}

	&Title2 {
		margin: 2rem 0 1rem;
		font-weight: 400;
	}
}