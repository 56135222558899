@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	display: flex;
	align-items: center;

	&Btn {

		&:global {

			&.btn-round {
				padding: 0 .75rem !important;
				min-width: 0 !important;
				border: 1px solid $alto-1 !important;
				color: #000 !important;
			}
		}
	}
}

.title {
	margin-right: .75rem;
	white-space: nowrap;
}

.subtitle {
	margin-right: 1rem;
	font-size: 17/16+rem;
	line-height: 21/16+em;
	color: $dusty-gray;
}

.select {

	&:local {

		&.select {
			margin-right: .75rem;
			width: 126/16+rem;
		}
	}
}

.pending {

	&:global {

		&.btn-square {
			padding: 0 .5rem;
			min-width: 0;
		}
	}
}

.dashboard {

	&:global {

		&.btn-round {
			padding: 0 1.5rem;

			.btn-round {

				&__prefix {
					left: auto;
					right: .75rem;
					transform: translate(50%, -50%);

					svg {
						min-width: 20/16+rem;

						path {

							&:first-child {
								fill: #fff;
							}
						}
					}
				}
			}

			.btn-content {
				letter-spacing: -.025em;
			}
		}
	}
}