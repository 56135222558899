@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	flex-grow: 1;
	padding-top: 1.5rem;
	padding-bottom: 70/16+rem;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20/16+rem;
}

.method {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: .5rem;
	}
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: .75rem;
	width: 20/16+rem;
	line-height: 0;

	svg {
		width: 100%;
		height: auto;
	}
}

.image {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: .75rem;
	width: 20/16+rem;

	img {
		max-width: 100%;
	}
}

.info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	h4 {
		@include f1-700();
		font-size: .5rem;
		line-height: 10/8+em;
		color: #000;
	}

	span {
		@include f1-400();
		font-size: .5rem;
		line-height: 10/8+em;
		color: #000;
	}
}

.tag {
	margin-left: .75rem;
	background: $fog;
}

.more {
	margin-left: auto;
}