@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.section {
	position: fixed;
	z-index: 20;
	top: 69/16+rem;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: stretch;
	background: #fff;
	transition: transform linear 0.3s;

	&:global {

		&._hidden {
			transform: translateX(100%);

			:local {

				.arrow {
					transition: transform 0.3s 0.3s;
					transform: translateX(-30/16+rem);

					svg {
						transform: rotate(0deg);
					}
				}
			}
		}
	}
}

.arrow {
	@extend %button;
	position: absolute;
	z-index: 2;
	bottom: 12/16+rem;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30/16+rem;
	height: 30/16+rem;
	background: $melrose;
	cursor: pointer;
	transition: transform 0.3s, background 0.3s;

	&:hover {
		background: $indigo;
	}

	svg {
		transform: rotate(180deg);
	}
}

.buttons {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem .5rem;
	min-width: 54/16+rem;
	border: 1/16+rem solid $wild-sand;
	background: #fff;

	&Item {
		position: relative;
	}

	&Notifications {
		position: absolute;
		top: -5/16+rem;
		right: -5/16+rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		background: $persimmon;
		box-shadow: 0 2/16+rem 4/16+rem 0 rgba(#000,.5);
		font-size: 7/16+rem;
		line-height: 8/7+em;
		color: #fff;
		text-align: center;
	}
}

.btn {
	@extend %button;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32/16+rem;
	height: 32/16+rem;
	border-radius: 50%;
	cursor: pointer;
	transition: box-shadow .25s ,background .25s;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&:global {

		&._active {
			box-shadow: 0 .125rem .25rem 0 rgba(#000,.5);
		}

		&._campaigns {
			background: #F6C6FF;

			&._active {
				background: #E976FF;
			}
		}

		&._creatives {
			background: #c7ffd8;

			&._active {
				background: #6EFF9A;
			}
		}

		&._finances {
			background: #A6B2FF;

			&._active {
				background: $conflower-blue;
			}
		}
	}

	svg {
		width: 18/16+rem;
		height: auto;
	}
}