@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.summary {
	width: 100%;
}

.title {
	margin-bottom: .25rem;
	color: $mine-shaft-1;
}

.pending {
	display: block;
	margin-bottom: .75rem;
	font-size: 12/16+rem;
	line-height: 15/12+em;
	color: $conflower-blue;
	text-decoration: underline;
}

.login {

	&:global {

		&.btn-round {
			margin-bottom: 36/16+rem;
			padding: 0 1.5rem;

			.btn-round {

				&__prefix {
					left: auto;
					right: .75rem;
					transform: translate(50%, -50%);

					svg {
						min-width: 20/16+rem;

						path {

							&:first-child {
								fill: #fff;
							}
						}
					}
				}
			}

			.btn-content {
				letter-spacing: -.025em;
			}
		}
	}
}

.item {
	font-size: 12/16+rem;
	line-height: 15/12+em;
	color: $dusty-gray;

	a {
		color: $dusty-gray;
		text-decoration: underline;
	}
}