@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {

	&:global {

		&._disabled {
			border: 1/16+rem solid rgba($alto-1,.3);
		}
	}
}