@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	flex-grow: 1;
	padding-top: 1.5rem;
	padding-bottom: 70/16+rem;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20/16+rem;
}

.address {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: .5rem;
	}
}

.tag {
	margin-left: .75rem;
	background: $fog;
}

.more {
	margin-left: auto;
}