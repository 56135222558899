@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.chart {
	flex: 1 1 1px;
	height: 1px;
	max-height: 100%;

	text {
		fill: $gray !important;
		font-family: 'Lato Regular', sans-serif !important;
		font-weight: 400;
		font-size: 11/16+rem !important;
		line-height: 13/11+em;
		color: $gray;
	}
	
	&:global {

		&._finances {
			
			text {
				color: #fff;
				fill: #fff !important;
			} 
		}
	}
}