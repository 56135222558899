@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.container {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 70/16+rem;
}

.info {
	flex: 0 1 350/16+rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 0 40/16+rem;
}

.title {
	margin-bottom: 2rem;
	@include f1-900();
	font-size: 44/16+rem;
	line-height: 52/44+em;
	color: #292667;
}

.image {
	flex: 0 1 440/16+rem;
	margin: 0 40/16+rem;
}

.p,
.item {
	@include f1-400();
	font-size: 15/16+rem;
	line-height: 18/15+em;
	color: #000;
}

.p {
	margin-bottom: 1.5rem;
}

.item {
	position: relative;
	padding-left: 1rem;

	&:not(:last-child) {
		margin-bottom: .75rem;
	}

	&:before {
		content: '';
		position: absolute;
		top: 9/16+rem;
		left: 0;
		transform: translateY(-50%);
		width: 5/16+rem;
		height: 5/16+rem;
		border-radius: 50%;
		background: #292667;
	}
}

.btn {
	align-self: center;
	margin-top: 20/16+rem;
}