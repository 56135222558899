@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.item {
	display: flex;
	align-items: center;
	padding: 0 1rem;
	height: 44/16+rem;
	cursor: pointer;

	&:hover {
		background: $zircon;
	}

	&:global {

		&._header {
			justify-content: space-between;
			background: transparent;
			cursor: default;
		}

		&._active {
			background: $fog;
		}
	}

	:global {

		.checkbox {

			&__label {

				svg {
					margin: 0;
				}
			}
		}
	}
}

.th {
	@include f1-700();
	font-size: 17/16+rem;
	line-height: 21/17+em;
	color: #000;

	&:last-child {
		text-align: right;
	}
}

.key {
	display: flex;
	align-items: center;
	font-size: 17/16+rem;
	line-height: 21/17+em;
	color: $mine-shaft-1;
}

.item {

	&:global {

		&._checked {

			:local {

				.key {
					color: $jacksons-purple;
				}
			}
		}
	}
}

.dot {
	margin-right: .5rem;
	min-width: .75rem;
	height: .75rem;
	border-radius: 50%;

	&:global {

		&._blue {
			background: $conflower-blue;
		}
	}
}

.value {
	margin-left: auto;
	color: $dove-gray;
}