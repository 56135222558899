@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	margin-bottom: -1rem;

	&Row {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&Select {

		&:global {

			&.select {
				margin-left: 1rem;
				width: 10rem;
			}
		}
	}
}

.close {
	margin-right: 1rem;
}

.title {
	margin-right: auto;
	white-space: nowrap;
}

.pending {
	justify-content: space-between;
	margin: 0 -1rem;
	padding: 1rem;
	background: linear-gradient(to right, #2A5671, #13193E);

	&Title {
		@include f1-900();
		font-size: 17/16+rem;
		line-height: 21/17+em;
		color: #fff;
		white-space: nowrap;
	}

	&Approve {
		padding: 0 .5rem;
	}
}