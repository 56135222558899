@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.main {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-width: 1280/16+rem;
	min-height: 100vh;
}
